(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu-container > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  // ---------
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      },
      $rotator,
      unveilSettings = {
        offset: 50,
        throttle: 200
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask({
    regex: '\\+7 \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $WINDOW
    .on('init', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.HEADER_HEIGHT = $HEADER.outerHeight();
    })
    .on('resize', function() {
      $WINDOW.trigger('init');
      $DOCUMENT
        .trigger('headerEvents')
        .trigger('headerMenuEvents')
        .trigger('rotatorImagesLoad');
    })
    .on('load', function() {
      $WINDOW.trigger('init');
      $DOCUMENT
        .trigger('headerEvents')
        .trigger('headerMenuEvents');
    })
    .trigger('init');


  $BODY
    .on('yiiListViewUpdating', function(e, id) {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function(e, id, data) {
      var $list = $('#'.concat(id, '[data-need-scroll]'));

      $('#'.concat(id, ' .catalog-buildings .item > div > div:first-child > a, ', '#', id, ' .catalog-buildings .item > div > div:first-child > span')).unveil(unveilSettings);

      if ($list.length) {
        var speed = $list.data('scrollSpeed') || 150,
            offset = ($list.data('scrollOffset') || 0);

        setTimeout(function() {
          $DOCUMENT.trigger('scrollTo', [$list.find('.list-view:first'), speed, offset]);
        });
      }

      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(function() {
      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Header banner rotator
      // ---------------------
      ($rotator = $('.main-rotator'))
        .one('init', function() {
          $DOCUMENT.trigger('rotatorImagesLoad');
        })
        .slick($.extend({}, slickSettings, {
          fade: true,
          adaptiveHeight: true,
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
          slide: '.main-rotator-slide',
          appendArrows: '.main-rotator-arrows',
          draggable: false
        }));


      // Load lazy images
      // ----------------
      $('.gallery-item img, .gallery-mini-block-container > div > div > div:first-child span, .catalog-buildings .item > div > div:first-child > a, .catalog-buildings .item > div > div:first-child > span').unveil(unveilSettings);


      // ProductSection2 filter
      // ----------------------
      if( $('.catalog-buildings-filter.js-filter').length ) {
        var $catalogGrid = $('.catalog-buildings-container .catalog-buildings > div').isotope({
          itemSelector: '.item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        });

        $DOCUMENT.on('click.catalogBuildingsFilter', '.catalog-buildings-container .catalog-buildings-filter.js-filter > div', function(e) {
          e.preventDefault();

          var $self = $(this),
              filterValue = $self.data('filter');

          if( !!filterValue ) {
            if( filterValue === 'all' ) {
              $self.addClass('active').siblings().removeClass('active');
              $catalogGrid.isotope({filter: '*'});
            }
            else
              if ($self.hasClass('active')) {
                $self.removeClass('active').siblings('[data-filter="all"]').addClass('active');
                $catalogGrid.isotope({filter: '*'});
              }
              else {
                $self.addClass('active').siblings().removeClass('active');
                $catalogGrid.isotope({filter: '[data-filter="'.concat($self.data('filter'), '"]')});
              }
          }
        });
      }
    })

    // Set header positioning
    // ----------------------
    .on('headerEvents', function() {
      $HEADER.addClass('fixed');

      if( IS_DESKTOP_WIDTH() ) {
        if( $rotator.length ) {
          $rotator.find('.main-rotator-slide-code').css('padding-top', HEADER_HEIGHT);
          $('.main-rotator-callback').css('padding-top', HEADER_HEIGHT);

          if( !$HEADER.hasClass('is-desktop-events') ) {
            $BODY.css('padding-top', 0);
            $HEADER.addClass('with-rotator is-desktop-events');

            $WINDOW
              .on('scroll.headerTransparency', function() {
                if( $WINDOW.scrollTop() > 0 ) {
                  $HEADER.removeClass('transparent');
                } else {
                  $HEADER.addClass('transparent');
                }
              })
              .trigger('scroll.headerTransparency');
          }
        }
        else {
          $BODY.css('padding-top', HEADER_HEIGHT);
          $HEADER.removeClass('with-rotator transparent is-desktop-events');
        }
      }
      else {
        $BODY.css('padding-top', HEADER_HEIGHT);
        $HEADER.removeClass('with-rotator transparent is-desktop-events');
        $rotator.find('.main-rotator-slide-code').css('padding-top', 0);
        $('.main-rotator-callback').css('padding-top', 0);
        $WINDOW.off('scroll.headerTransparency');
      }
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        if( !$HEADER_MENU.hasClass('is-desktop-events') ) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .css('top', '')
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered')
            .find('ul').css('display', '');
        }
      }
      else {
        $HEADER_MENU.css('top', HEADER_HEIGHT);

        if( !$HEADER_MENU.hasClass('is-mobile-events') ) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-container > ul').length || $(e.target).closest('.header-menu-button-container').length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > ul a, .header-menu-container > ul li > div > div > span', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings().removeClass('is-hovered').children('ul').slideUp(300);

              if( isWithDropdown ) {
                if( isOnHover ) {
                  if( $self.prop('tagName').toUpperCase() === 'A' )
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(300);
                } else {
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              } else {
                if( $self.prop('tagName').toUpperCase() === 'A' )
                  location.href = $self.prop('href');
              }
            });
        }
      }
    })

    // Load rotator images
    .on('rotatorImagesLoad', function() {
      if( $rotator.length ) {
        var dataKey = 'imageBig';

        if( IS_MOBILE_WIDTH() )
          dataKey = 'imageSmall';
        else if( !IS_DESKTOP_WIDTH() )
          dataKey = 'imageMedium';

        $rotator.find('.main-rotator-slide-image > div').each(function() {
          var $self = $(this),
              image = $self.data(dataKey);

          $('<img/>').attr('src', image).on('load', function() {
            $(this).remove();

            $self
              .css('background-image', 'url(\''.concat(image, '\')'))
              .addClass('no-blur');
          });
        });
      }
    })

    // Show rotator callback form
    // --------------------------
    .on('click.rotatorCallbackShow', '.rotator-callback-show-button', function(e) {
      e.preventDefault();

      $(this).parent().addClass('form-show');
    })

    // Why we block events
    .on('click.whyWeShow', '.why-we-block-container .why-we-button-show', function(e) {
      e.preventDefault();

      var $container = $('.why-we-block-container');

      $WINDOW.scrollTo($container, {
        axis: 'y',
        duration: 150,
        offset: -30-HEADER_HEIGHT,
        start: function() { $container.addClass('opened').children('.why-we-preview').animate({'height': '0px'}, 500); }
      });
    })
    .on('click.whyWeClose', '.why-we-block-container .why-we-button-hide, .why-we-block-container .why-we-button-hide-sticky', function(e) {
      e.preventDefault();

      var $container = $('.why-we-block-container');

      $WINDOW.scrollTo($container, {
        axis: 'y',
        duration: 150,
        offset: -30-HEADER_HEIGHT,
        start: function() { $container.removeClass('opened').children('.why-we-preview').css('height', 'unset'); }
      });
    })

    // Links events
    // ------------
    .on('click.catalogSection2', '.catalog-buildings .item > div > div:first-child + div + div .catalog-building-tour', function() {
      $('#CallbackSection2_section2Id_'.concat($(this).data('form2Id'))).val($(this).data('section2Id'));

      $.fancybox.open({
        src: '#callback-section2-content',
        type: 'inline'
      })
    })
    .on('click.privacyLinkHandle', '.privacy-link-handle', function() {
      CALLBACKS.privacyLinkClick();
    })

    // Scroll to
    // ---------
    .on('scrollTo', function(e, $element, speed, offset) {
      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 150;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - HEADER_HEIGHT});
    })
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $link = $(this),
          $element = $($link.attr('href').substr($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if (current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH()) {
        current.$content.find('.js-autofocus-inp').trigger('focus');
      }
    }
  });
})();